<template>
    <div class="yj-conten" style="background-color:#fff;width:calc(100% - 32px); min-width:fit-content">
        <a-layout-content style="padding:0;">
            <div class="yj-conten " style="background: #fff;padding: 20px 20px 0;margin: 0;">
                <a-row class="yj-form">
                    <a-col :span="20">
                        <a-form layout="horizontal" style="padding:10px  20px 0px;height:auto;">
                            <a-form-item label="" style="margin-bottom:10px;"
                                         :label-col="formItemLayout.labelCol"
                                         :wrapper-col="formItemLayout.wrapperCol">
                                <a-form layout='inline'>
                                    <a-form-item label="状态">
                                        <a-radio-group default-value="Online" v-model="Online" @change="getTableData">
                                            <a-radio-button value="-1">全部</a-radio-button>
                                            <a-radio-button value="1">销售中</a-radio-button>
                                            <a-radio-button value="0">已下架</a-radio-button>
                                        </a-radio-group>
                                    </a-form-item>
                                </a-form>
                            </a-form-item>
                            <a-form-item label="" style="margin-bottom:0px;"
                                         :label-col="formItemLayout.labelCol"
                                         :wrapper-col="formItemLayout.wrapperCol">
                                <a-form layout='inline'>
                                    <a-form-item label="商品">
                                        <a-input placeholder="输入商品名称" v-model="name" :value="name" style="width:278px;" />
                                    </a-form-item>
                                    <a-form-item label="">
                                        <a-button type="primary" class="margin-r" @click="search">
                                            查询
                                        </a-button>
                                    </a-form-item>
                                </a-form>
                            </a-form-item>
                        </a-form>
                    </a-col>
                    <a-col :span="4" class="textal_r">
                        <a-button type="primary" @click="showProduct()" :disabled="disabled">选择商品</a-button>
                        <a-button type="primary" @click="addRow()" :disabled="disabled">新增商品</a-button>
                    </a-col>
                </a-row>
                <a-table :columns="columns"
                         rowKey="ID"
                         :data-source="tableData"
                         :pagination="pagination"
                         @change="pagerChange"
                         style=" min-height: calc(100vh - 234px);min-width: max-content"
                         class="yj-table-img">
                    <span slot="action" slot-scope="text, record">
                        <a v-if="record.Online==1||disabled" @click="editRow(record,1,1)">佣金详情</a>
                        <a-divider type="vertical" v-if="record.Online==1||disabled" />
                        <a v-if="record.Online==1||disabled" @click="editRow(record,0,1)">商品详情</a>
                        <a-divider type="vertical" v-if="record.Online==1||disabled" />
                        <a v-if="record.Online==0&&disabled==false" @click="editRow(record,1,0)">佣金</a>
                        <a-divider type="vertical" v-if="record.Online==0&&disabled==false" />
                        <a v-if="record.Online==0&&disabled==false" @click="editRow(record,0,0)">编辑</a>
                        <a-divider type="vertical" v-if="record.Online==0&&disabled==false" />
                        <a @click="UpdateOnline(record)" v-if="disabled==false">{{ record.Online==1?'下架':'上架' }}</a>
                        <a-divider v-if="record.Online==0&&disabled==false" type="vertical" />
                        <a v-if="record.Online==0&&disabled==false" @click="delRow(record)" style=" color:red">删除</a>
                    </span>
                    <span slot="Online" slot-scope="text, record">
                        <span v-if="ShowEnum(record.Online)=='已下架'">{{ShowEnum(record.Online)}}</span>
                        <span v-else style="color:#52ca5c">
                            {{ShowEnum(record.Online)}}
                        </span>
                    </span>
                </a-table>
            </div>
        </a-layout-content>
        <Product_Add_Dialog v-bind:Show="modalVisible" v-on:hide="modalVisible=false" v-bind:M2ActivityID="M2ActivityID" v-bind:M2ActivityProductID="M2ActivityProductID" v-bind:ReturnUrl="ReturnUrl"></Product_Add_Dialog>
        <a-modal v-model="Product.IsDisplay"
                 title="商品选择"
                 centered
                 okText="确定"
                 cancelText="取消"
                 width="900px"
                 height="700px"
                 @ok="onProductOk">
            <div style="width: 100%; height:auto;">
                <ul class="form-search">
                    <li style="margin:0">
                        <label>名称</label>
                        <a-input placeholder="输入名称" v-model="Product.Name"></a-input>
                    </li>
                </ul>
                <a-button type="primary" value="查询" @click="getProductList" size="small" style="margin-left:12px;height:28px;padding-left:12px;padding-right:12px;">查询</a-button>
                <div id="boxdiv" style="padding-top:16px;">
                    <a-radio-group v-model="Product.ID" @change="onProductChange">
                        <table id="example2" class="table  table-hover">
                            <thead>
                                <tr>
                                    <th width="40"></th>
                                    <th width="100">商品图片</th>
                                    <th>名称</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in Product.tableData" :key="item.ID">
                                    <td>
                                        <a-radio :value="item.ID"></a-radio>
                                    </td>
                                    <td align="center">
                                        <img :src="Getsrc(item)" height="80" width="80" />
                                    </td>
                                    <td title="Name">{{item.Name}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </a-radio-group>
                </div>
            </div>
        </a-modal>
    </div>
</template>
<script>
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    import Product_Add_Dialog from "@/Views/ShopModule/Product/Add.vue";
    export default {
        name: "M2Activity_ProductList",
        data() {
            return {
                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 2 },
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 22 },
                    },
                },
                Online: "-1",
                pagination: {
                    total: 0,
                    defaultPageSize: 0,
                    current: 1,
                    showTotal: total => `共 ${total} 条数据`
                },
                name: "",
                tableData: [],
                ID: 0,
                jsondata: [],
                M2ActivityID: 0,
                M2ActivityProductID: 0,
                ReturnUrl: 'M2Activity_ProductList',
                disabled: false,
                modalVisible: false,
                Product: {
                    ID: 0,
                    IsDisplay: false,
                    Name: '',
                    tableData: [],
                    disabled: false,
                    ProductType: 0
                },
                columns: [
                    {
                        title: "商品名称",
                        width: 360,
                        customRender: (text, item) => {
                            var src = '#';
                            var label = item.Name;
                            var intro = item.Summary
                            if (item.ProductExImage.length > 0) {
                                src = item.ProductExImage[0].ImagePath;
                            }
                            return <div><img src={src} width="50px" height="50px" style="float: left;margin-right:16px;" /><strong class='goods-name'>{label}</strong><p class='intoduce' style="">{intro}</p></div>;
                        }
                    },
                    {
                        title: "价格",
                        dataIndex: "SaleMoney",
                        align: "center",
                        width: 200,
                        customRender: (text, item) => {
                            return util.ToFixed(item.SaleMoney);
                        }
                    },
                    {
                        title: "预付金额",
                        dataIndex: "AdvanceMoney",
                        align: "center",
                        width: 160,
                        customRender: (text, item) => {
                            var rt = util.ToFixed(item.AdvanceMoney);
                            if (item.BuyMoneyType == "0") {
                                rt = util.ToFixed(0);
                            }
                            return rt;
                        }
                    },
                    {
                        title: "销量",
                        dataIndex: "BuyCount",
                        align: "center",
                        width: 120
                    },
                    {
                        title: "状态",
                        width: 200,
                        align: "center",
                        scopedSlots: { customRender: 'Online' }
                    },
                    {
                        title: '操作',
                        align: "center",
                        width: 350,
                        scopedSlots: { customRender: 'action' }
                    }
                ],
            };
        },
        props: {//组件属性
        },
        methods: {//方法
            getTableData: function () {
                var self = this;
                var op = {
                    url: "/ShopModule/Product/ProductList",
                    data: {
                        pageIndex: self.pagination.current,
                        name: self.name,
                        Online: self.Online,
                        M2ActivityID: self.ID
                    },
                    OnSuccess: function (data) {
                        self.tableData = data.data.Data;
                        self.pagination.total = data.data.Total;
                        self.pagination.defaultPageSize = data.data.RowCount;
                        self.pagination.current = data.data.PageIndex;
                    }
                };
                http.Post(op);
            },
            search: function () {
                var self = this;
                self.pagination.current = 1;
                self.getTableData();
            },
            pagerChange: function (item) {
                var self = this;
                self.pagination.current = item.current;
                self.getTableData();
            },
            getData2: function () {//获取枚举信息
                var Self = this;
                var op = {
                    url: "/Enum",
                    data: {
                        AssemblyName: "Welcome.ProductModule",//程序集名称
                        TypeName: "Welcome.ProductModule.Enumerate.EnumOnline"//枚举类型名称。要带上命名空间的全称
                    },
                    OnSuccess: function (response) {
                        Self.jsondata = response.data
                    }
                }
                http.Post(op);
            },
            ShowEnum: function (Key) {//枚举不是从0开始，或不连续，可以使用此方法
                var Self = this;
                var item = Self.jsondata.find(function (item) {
                    return item.ID == Key;
                });
                if (item)
                    return item.Name
                else
                    return "";
            },
            editRow(e, b, c) {
                var self = this;
                self.$router.push({
                    name: "ProductDetail",
                    query: { id: e.ID, commission: b, disabled: c, ProductType: e.ProductType, M2ActivityID: 0, M2ActivityProductID: 0, ReturnUrl: self.ReturnUrl }
                });
            },
            UpdateOnline(e) {
                var self = this;
                var con = e.Online == 1 ? '下架' : '上架';
                var status = e.Online == 1 ? 0 : 1;
                this.$confirm({
                    title: '提示',
                    content: '是否' + con + e.Name,
                    okText: "确定",
                    cancelText: '取消',
                    onOk() {
                        var op = {
                            url: "/ShopModule/Product/UpdateOnline",
                            data: {
                                id: e.ID,
                                Online: status
                            },
                            OnSuccess: function () {
                                self.pagination.current = 1;
                                self.getTableData();
                                self.Tipsconfirm(con + e.Name);
                            }
                        };
                        http.Post(op);
                    },
                    onCancel() { },
                });
            },
            delRow(e) {
                var self = this;
                this.$confirm({
                    title: '提示',
                    content: '是否删除' + e.Name,
                    okText: "确定",
                    cancelText: '取消',
                    onOk() {
                        var op = {
                            url: "/ShopModule/Product/DeleteProduct",
                            data: {
                                id: e.ID
                            },
                            OnSuccess: function () {
                                self.pagination.current = 1;
                                self.getTableData();
                                self.Tipsconfirm('删除' + e.Name);
                            }
                        };
                        http.Post(op);
                    },
                    onCancel() { },
                });
            },
            Tipsconfirm(e) {
                this.$info({
                    title: '提示',
                    content: '成功' + e,
                    okText: "确定"
                });
            },
            addRow() {
                this.modalVisible = true;
            },
            showProduct: function () {
                var self = this;
                self.Product.IsDisplay = true;
            },
            onProductOk: function () {
                var self = this;
                self.Product.IsDisplay = false;
                var result = self.Product.tableData.filter(function (item) {
                    return item.ID == self.Product.ID;
                });
                self.Product.ProductType = result[0].ProductType;
                self.$router.push({
                    name: "ProductDetail",
                    query: { id: 0, commission: 0, disabled: 0, ProductType: self.Product.ProductType, M2ActivityID: self.ID, M2ActivityProductID: self.Product.ID, ReturnUrl: self.ReturnUrl }
                });
            },
            getProductList: function () {
                var self = this
                var op = {
                    url: "/ShopModule/Product/ProductList",
                    data: {
                        Name: self.Product.Name,
                        M2ActivityID: 0
                    },
                    OnSuccess: function (data) {
                        if (data.data.Data.length > 0) {
                            self.Product.tableData = data.data.Data;
                            self.Product.ID = data.data.Data[0].ID;
                        } else {
                            self.Product.tableData = [];
                            self.Product.ID = 0;
                        }
                    }
                };
                http.Post(op);
            },
            onProductChange(e) {
                var self = this;
                self.Product.ID = e.target.value;
            },
            Getsrc(e) {
                var src = '#';
                if (e.ProductExImage.length > 0) {
                    src = e.ProductExImage[0].ImagePath;
                }
                return src;
            },
        },
        computed: {//计算属性
        },
        watch: {//侦听属性
        },
        components: {//组件
            Product_Add_Dialog
        },
        beforeCreate: function () {//生命周期，创建前
        },
        created: function () {//生命周期，创建完成
            this.ID = this.$route.query.ID;
            this.M2ActivityID = this.$route.query.ID;
            if (this.$route.query.disabled == 1) {
                this.disabled = true;
            }
            this.ReturnUrl = "M2Activity_ProductList?ID=" + this.ID + "|disabled=" + this.$route.query.disabled;
        },
        beforeMount: function () {//生命周期，组装前
        },
        mounted: function () {//生命周期，组装完成
            this.getTableData();
            this.getData2();
            this.getProductList();
        },
        beforeUpdate: function () {//生命周期，data更新前
        },
        updated: function () {//生命周期，data更新完成
        },
        beforeDestroy: function () {//生命周期，销毁前
        },
        destroyed: function () {//生命周期，销毁完成
        }
    };
</script>